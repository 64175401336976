import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про нас | Відкрийте для себе оренду SwiftWheels
			</title>
			<meta name={"description"} content={"Кожна миля розповідає історію"} />
			<meta property={"og:title"} content={"Про нас | Відкрийте для себе оренду SwiftWheels"} />
			<meta property={"og:description"} content={"Кожна миля розповідає історію"} />
			<meta property={"og:image"} content={"https://glowshub.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://glowshub.com/img/images.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://glowshub.com/img/images.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://glowshub.com/img/images.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://glowshub.com/img/images.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://glowshub.com/img/images.png"} />
			<meta name={"msapplication-TileImage"} content={"https://glowshub.com/img/images.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 180px 0" lg-padding="90px 0 90px 0" sm-padding="45px 0 60px 0" quarkly-title="About-15">
			<Override slot="SectionContent" flex-direction="row" lg-flex-wrap="wrap" sm-min-width="280px" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				justify-content="space-between"
				lg-width="100%"
				lg-align-items="stretch"
				lg-flex-direction="row"
				lg-justify-content="flex-start"
				md-flex-direction="column"
			>
				<Box
					lg-width="100%"
					lg-display="flex"
					lg-flex-direction="column"
					lg-align-items="flex-start"
					lg-justify-content="flex-end"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="space-around"
				>
					<Text
						margin="0px 0px 12px 0px"
						font="--base"
						text-transform="uppercase"
						color="--dark"
						letter-spacing="1px"
					>
						Про Нас
					</Text>
					<Text
						margin="0px 0px 24px 0px"
						font="--headline2"
						color="--dark"
						lg-text-align="left"
						md-padding="0px 0px 0px 0px"
					>
						Наша подорож
					</Text>
					<Text
						margin="0px 0px 24px 0px"
						font="--base"
						color="--greyD2"
						md-text-align="left"
						lg-flex="1 1 0%"
						lg-padding="0px 0px 0px 0px"
						lg-margin="0px 0px 32px 0px"
						sm-margin="0px 0px 28px 0px"
					>
						Компанія SwiftWheels Rentals розпочала свою діяльність з простої місії: надавати надійні, високоякісні автомобілі для мандрівників, які шукають свободи та пригод на відкритій дорозі. З роками ми виросли зі скромного автопарку до добре зарекомендувавшої себе служби прокату автомобілів, відомої своєю прихильністю до задоволення потреб клієнтів і любов'ю до подорожей, так само як і наші клієнти. Наша історія побудована на довірі, сервісі та невпинному прагненні покращити ваші враження від подорожей.
					</Text>
					<Link
						href="/contact"
						color="--lightD1"
						text-decoration-line="initial"
						padding="7px 24px 8px 24px"
						background="--color-primary"
						font="--base"
						transition="--opacityOut"
						hover-opacity=".7"
						letter-spacing="1px"
						border-radius="4px"
					>
						Забронювати
					</Link>
				</Box>
			</Box>
			<Box
				width="80%"
				display="flex"
				justify-content="flex-end"
				lg-width="100%"
				lg-margin="48px 0px 0px 0px"
				lg-flex-direction="column"
			>
				<Box position="relative">
					<Image
						src="https://glowshub.com/img/3.jpg"
						display="block"
						object-fit="cover"
						height="380px"
						width="550px"
						lg-width="100%"
						lg-height="auto"
						lg-margin="8px 0px 0px 0px"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://glowshub.com/img/4.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Чому SwiftWheels виділяється
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Неперевершена турбота про клієнтів: Ми віримо в те, що ставимося до кожного клієнта як до члена сім'ї, забезпечуючи індивідуальний підхід до обслуговування кожного разу.
					<br />
					<br />
					Ніяких прихованих комісій: Прозорість є ключовим фактором у всіх наших транзакціях. Що бачите, за те й платите.
					<br />
					<br />
					Допомога на дорозі: Душевний спокій гарантовано завдяки нашій цілодобовій допомозі на дорозі, доступній у будь-який час, коли вона вам знадобиться.
					<br />
					<br />
					Екологічні варіанти: Ми прагнемо до сталого розвитку, пропонуючи вибір екологічно чистих транспортних засобів, щоб зменшити ваш вуглецевий слід під час подорожей.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Приєднуйтесь до нашої подорожі
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					У SwiftWheels Rentals ваша пригода так само важлива для нас, як і для вас. Ми запрошуємо вас створювати власні історії на дорозі, підтримуючи нашу відданість наданню вам найкращих транспортних засобів для цього. Оберіть нас для своєї наступної подорожі та відчуйте безперебійний процес оренди, розроблений відповідно до ваших потреб та побажань.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://glowshub.com/img/5.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});